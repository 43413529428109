<template>
  <orders-data-table
    :default-filter="advancedFilter"
    use-user-tags
  />
</template>
<script>
import OrdersDataTable from '@/components/page/orders/OrdersDataTable.vue'

export default {
  components: {
    OrdersDataTable,
  },
  data() {
    return {
      advancedFilter: {
        state: [], search: '', viewZeroCostOrders: false, tag: [], userTags: [], processor: [],
      },
    }
  },
}
</script>
